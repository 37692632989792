import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Contact from "./components/Contact";
import NavBar from "./components/NavBar";
import Projects from "./components/Projects";
import Home from "./components/Home";
import Experience from "./components/Experience";
import Education from "./components/Education";
import Skills from "./components/Skills";
import "./app.css"
import About from "./components/About";
import SampleDesigns from "./components/SampleDesigns";

function App() {
  return (
    <Router>
      <NavBar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        {/* <Route exact path="/education">
          <Education />
        </Route> */}
        {/* <Route exact path="/experience">
          <Experience />
        </Route> */}
        <Route exact path="/projects">
          <Projects />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/sample">
          <SampleDesigns />
        </Route>
        {/* <Route exact path="/about">
          <About />
        </Route> */}
        {/* <Route exact path="/skills">
          <Skills />
        </Route> */}
      </Switch>
    </Router>
  );
}

export default App;
