import React from 'react'
import "../app.css"

const Character = () => {
  return (
    <div id="animation">
      <div id="frame"></div>
      <div id="roll"></div>
    </div>

  )
}

export default Character
