import React from "react";
import Card from "./Card";
import ChatApplication from "../images/chatApplication.jpg";
import SignUp from "../images/signUp.jpg";
import PricingPage from "../images/pricingPage.jpg";
const sampleDesigns = [
	{
		title: "Chat Application Dashboard",
		description: "",
		imgSrc: ChatApplication,
	},
	{
		title: "Sign In/Sign Up Page",
		imgSrc: SignUp,
	},
	{
		title: "Pricing Page",
		imgSrc: PricingPage,
	},
];
const SampleDesigns = () => {
	return (
		<>
			<div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 m-10">
				<div className="text-center">
					<h1 className="font-bold text-4xl md:text-5xl font-heading text-gray-800">
						<span className="reveal">Sample Designs</span>
					</h1>
				</div>
				<div className="mt-10 grid grid-rows grid-flow-col gap-4">
					{sampleDesigns.map((design) => (
						<Card title={design.title} imgSrc={design.imgSrc} />
					))}
				</div>
			</div>
		</>
	);
};

export default SampleDesigns;
