import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import illumidesk from "../images/illumidesk.svg"
import conzia from "../images/conzia.png"
import YoutubeEmbed from "./YoutubeEmbed";
import { projects } from "./projectsContent";

export default function Projects() {
  let [isOpen, setIsOpen] = useState(false);
  const classLeft = 'w-full bg-white rounded-lg sahdow-lg overflow-hidden flex flex-col md:flex-row';
  const classRight = 'w-full bg-white rounded-lg sahdow-lg overflow-hidden flex flex-col md:flex-row-reverse'

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 m-10">
      <div className="text-center">
        <h1 className="font-bold text-4xl md:text-5xl font-heading text-gray-800">
          <span className="reveal">Projects</span>
        </h1>
      </div>
      <div className="mt-5 grid grid-cols-1 gap-6">
        {projects.map((project, index) => (
          <div className={index % 2 === 0 ? classLeft : classRight}>
            <div className="w-full md:w-2/4  border-r-2 flex items-center justify-center">
              <img
                className="object-center bottom-[#f3f4f6] w-4/5 h-5/6"
                src={project.imgSrc}
                alt=""
              />
            </div>
            <div className="w-full md:w-2/4  border-r-2 text-left p-6 md:p-4">
              <p className="text-2xl text-gray-800 font-bold">{project.title}</p>
              <p className="text-base leading-relaxed text-gray-800 font-normal">{project.details}</p>
              {project.siteURL && (
                <div className="mt-6">
                  <a
                    href={project.siteURL}
                    target="_blank"
                    className="uppercase py-2 my-2 px-4 bg-transparent dark:text-gray-800 dark:bg-white hover:dark:bg-gray-100 border-2 border-gray-800 text-gray-800 dark:text-white hover:bg-gray-800 hover:text-white text-md" rel="noreferrer"
                  >
                    View Site
                  </a>
                  {project.youtubeEmbed && (
                    <YoutubeEmbed embedId={project.youtubeEmbed} />
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 rounded-md text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-400 bg-opacity-50" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <img
                  className="object-center object-cover w-full h-full cursor-pointer"
                  src="https://via.placeholder.com/720"
                  alt=""
                  onClick={openModal}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
