import illumidesk from "../images/illumidesk.svg"
import conzia from "../images/conzia.png"
import unigamer from "../images/unigamer.svg"
import optum from '../images/optum.svg'
import idid from "../images/idid.png"
import omnigear from "../images/omnigear.png"
import uptok from "../images/uptok.png"
export const projects = [
	{
		title: "Optum Store",
		imgSrc: optum,
		siteURL: "https://store.optum.com/",
		details:
			"Optum store is an e-commerce platform provided by Optum Company, which focuses on providing pharmacy services through an e-commerce platform.",
		techStack: ["ReactJs", "Typescript", "CircleCi", "NodeJs", "Jest"],
	},
	{
		title: "Illumidesk",
		imgSrc: illumidesk,
		siteURL: "https://www.illumidesk.com/",
		details: `IllumiDesk is an interactive environment that allows you to create and share documents
    that contain live code, equations, visualizations, and explanatory text.
    Jupyter notebooks can incorporate markdown elements, allowing you to easily create
    readable reports that include equations and rich media, with its admin dashboard you can create virtual campuses and
    manage resources.`,
		techStack: [
			"ReactJs",
			"NextJs",
			"Typescript",
			"MongoDB",
			"ExpressJs",
			"Nbgrader",
			"Python",
			"Cypress",
		],
	},
	{
		title: "Live Chat, Facebook, Messenger",
		imgSrc: conzia,
		siteURL:
			"https://apps.shopify.com/conzia?surface_detail=Live+chat&surface_inter_position=1&surface_intra_position=10&surface_type=search",
		youtubeEmbed: "u5cGDMp1dak",
		details: `This is a chat application which allows shopify store owners to
    communicate with their customers by having a chat widget provided by Conzia on their stores. `,
		techStack: [
			"ReactJs",
			"NextJs",
			"Typescript",
			"Javascript",
			"MongoDB",
			"NestJs",
			"AWS",
			"Socket.io",
		],
	},
];