import React, { useState } from 'react'

const YoutubeEmbed = ({embedId}) => {
   const [showIframe, setShowIframe] = useState(false);
console.log(showIframe)
  return (
    <>
    {
      showIframe ? (
        <>
         
          <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
              <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                 <div className="video-responsive">
                  <span
              onClick={() => setShowIframe(false)}
              className="absolute py-2 px-4 bg-transparent hover:dark:bg-gray-100 border-2 border-gray-800 text-gray-800 dark:text-white hover:bg-gray-800 hover:text-white text-md"
            >
            X
            </span>

                  <iframe
                    src={`https://www.youtube.com/embed/${embedId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Demo Video"
                  />
                </div>
            </div>
          </div>
        </>
      ) : null
    }
     <button
        onClick={() => setShowIframe(!showIframe)}
        className="ml-4 uppercase py-2 my-2 px-4 bg-transparent dark:text-gray-800 dark:bg-white hover:dark:bg-gray-100 border-2 border-gray-800 text-gray-800 dark:text-white hover:bg-gray-800 hover:text-white text-md"
      >Video Demo</button>
    </>
  )
}

export default YoutubeEmbed

